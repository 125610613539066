import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import store from "./plugins/redux";
import {persistStore} from "redux-persist";
import {PersistGate} from "redux-persist/integration/react";
import {createApi} from "./api/index";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

let persistor = persistStore(store);
const queryClient = new QueryClient();

createApi();

let App;

switch (process.env.REACT_APP_SERVER) {
    case "scg":
        App = React.lazy(() => import("./scg/App"));
        break;
    case "cfdx":
        App = React.lazy(() => import("./cfdx/App"));
        break;
    default:
        App = React.lazy(() => import("./scg/App"));
}

ReactDOM.render(
    <React.Fragment>
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <BrowserRouter>
                    <PersistGate loading={null} persistor={persistor}>
                        <React.Suspense fallback={<div></div>}>
                            <App/>
                        </React.Suspense>
                    </PersistGate>
                </BrowserRouter>
            </Provider>
        </QueryClientProvider>
    </React.Fragment>,
    document.getElementById("root")
);